<template>
  <!--维护通知-->
  <van-dialog  title="重要公告" >
    <div class="p-3 pre-wrap fs-14">
      <div class="">亲爱的爱豆们：<br/>
        <span>对于今天的播货建议，我们有如下两点提示：</span><br/>
        1. 在自己的播货群内不要出现任何娱乐话题和措辞，素材中避免红色、橙色等所有喜庆元素；<br/>

        2. 今天上午9:00至12:00，我们会进行服务器维护，将暂停爱跟推的相关功能，建议大家在此时间段进行手动播货。<br/>
        感谢您的配合！
      </div>
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: "TheNoticeDialog"
}
</script>
<script setup>

</script>

<style scoped>

</style>
